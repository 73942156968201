import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    ns: [],
    fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['cookie', 'navigator']
    },
    backend: {
      loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json'
    }
  })

export default i18n
